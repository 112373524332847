<template>
  <v-container fluid grid-list-xs fill-height class="container">
    <v-layout row wrap class="layout header_bg" fluid>
      <v-flex xs12 class="row_title">
        <v-layout
          row
          style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
            font-size: 5vw;
          "
          class="grey lighten-5"
        >
          <v-flex xs3 class="text-left ml-0 pl-0 mb-2 mt-1">
            <v-btn icon @click="OnBackToReport()">
              <v-icon color="pink lighten-1" large
                >mdi-arrow-left-bold-circle</v-icon
              >
            </v-btn>
          </v-flex>

          <v-flex xs9 class="text-left mt-2 pink--text text--darken-1">
            อัตราจ่าย / เปอร์เซ็นต์</v-flex
          >
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
      </v-flex>

      <v-flex xs12>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="mt-3 mb-1 py-0"
        >
          <v-flex xs12 class="text-center">
            อัตราประจำงวด {{ getFullPeriodThai(PeriodDT) }}
          </v-flex>
        </v-layout>
        <v-layout
          row
          style="flex-flow: row;"
          class="mt-1 mb-0 py-0"
        >
          <v-flex xs12 class="text-right">
            เจ้าให้เปอร์เซ็นต์
          </v-flex>
        </v-layout>
        <v-simple-table class="mt-0" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="border border-bottom text-center" width="33%">
                  รายการถูก
                </th>
                <th class="border border-bottom text-center" width="33%">
                  เจ้าจ่าย (บาทละ)
                </th>
                <th
                  class="border border-bottom text-center border-right"
                  width="33%"
                >
                  เปอร์เซ็นต์
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in PrizeRate" :key="i">
                <td class="border border-bottom text-center" width="33%">
                  {{ GetPrizeText(item.BetTypeShowID) }}
                </td>
                <td class="border border-bottom text-center" width="33%">
                  {{ item.PrizeRate }}
                </td>
                <td
                  class="border border-bottom text-center border-right"
                  width="33%"
                >
                  {{ item.AgentPercent }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </v-container>

  <!-- <div>ตาราง</div> -->

  <!-- <div>ยอดลอย</div> -->
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

// import ReportRow from "@/components/Report/CoverPage/Row";

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    //component name,
    // ReportRow,
  },
  computed: {
    // // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    // PriceSum() {
    //   return parseInt(this.$store.getters["page/PageData"].PriceSum);
    // },
  },
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    //   this.PageNumber = this.$route.query.PageNumber;
    //   this.CusID = this.$route.query.CusID;
    //   // this.updateDataTableHeight();
    //   setTimeout(() => {
    //     this.updateDataTableHeight();
    //   }, 500);
    this.GetData();
  },
  methods: {
    async GetData() {
      let parameter = {
        to: "Report",
        method: "rate",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
            this.PrizeRate = response.REPORT_RATE;
        },
        (error) => {}
      );
    },

    GetPrizeText(p_BetTypeShow){
        let PrizeText = ["3 ตัวตรง","3 ตัวโต๊ด","2 ตัวบน","2 ตัวล่าง","คู่โต๊ดบน","พวง","หมุน","จม","ลอยบน","ลอยล่าง"];
        return PrizeText[parseInt(p_BetTypeShow) - 1];
    },
    OnBackToReport() {
      this.$router.push({
        path: "Reports",
      });
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    PrizeRate: [],

    CloseTime: "",
    PeriodStatus: 0,
    PeriodIsOpen: 0,
  }),
};
</script>

<style scoped>
.container {
  background-color: white;
  flex-grow: 1;
  flex-shrink: 0;
}
.layout {
  flex-flow: column;
  padding: 2px;
}
.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
}

.border {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.border-bottom {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
}
.border-right {
  border-right: 1px solid black;
}
</style>
